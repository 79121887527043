import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import Button from "components/ui/Button";

const StyledMuiDialog = styled(MuiDialog)({
  minWidth: 300,
  ".MuiDialog-paper": { minWidth: 300 },
});
const StyledDialogTitle = styled(DialogTitle)((props) => ({
  paddingBottom: 16,
}));
const Title = styled(Typography)((props) => ({
  ...props.theme.typography.h4,
  marginBottom: 0,
}));
const StyledDialogContent = styled(DialogContent)({
  paddingBottom: 24,
  ".MuiDialogContentText-root": {
    // fontSize: "1rem",
    lineHeight: 1.5,
    marginBottom: 0,
  },
});

const Loader = () => (
  <div style={{ padding: 50 }}>
    <CircularProgress size={50} />
  </div>
);

export default function Dialog({
  children,
  className,
  open,
  loading = false,
  handleCancel,
  handleConfirm = () => {},
  onClose,
  title,
  style,
  agreeText,
  disagreeText,
}) {
  const buttonText1 = agreeText ? agreeText : "Agree";
  const buttonText2 = disagreeText ? disagreeText : "Disagree";

  const handleAgree = () => {
    handleConfirm();
    if (onClose) onClose();
  };

  const handleDisagree = () => {
    if (handleCancel) handleCancel();
    if (onClose) onClose();
  };

  const renderContent = () => {
    if (loading) return <Loader />;

    return (
      <>
        <StyledDialogTitle>
          <Title>{title}</Title>
        </StyledDialogTitle>
        <StyledDialogContent>{children}</StyledDialogContent>
        <DialogActions>
          {handleCancel && (
            <Button variant="low-emphasis" onClick={handleDisagree}>
              {buttonText2}
            </Button>
          )}
          <Button variant="contained" onClick={handleAgree}>
            {buttonText1}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <StyledMuiDialog
      className={className}
      style={style}
      open={open}
      onClose={onClose}
      disableEnforceFocus
    >
      {renderContent()}
    </StyledMuiDialog>
  );
}
