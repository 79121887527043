import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

const StyledDialogTitle = styled(MuiDialogTitle)((props) => ({
  paddingTop: 20,
  paddingBottom: props.showBorders ? 20 : 4,
  borderBottom: props.showBorders
    ? `1px solid ${props.theme.palette.dividerFaint}`
    : "none",
}));
const Title = styled(Typography)((props) => ({
  ...props.theme.typography.h5,
  lineHeight: 1,
  marginBottom: 0,
}));

export default function DialogTitle({ children, showBorders = false }) {
  return (
    <StyledDialogTitle showBorders={showBorders} disableTypography>
      <Title>{children}</Title>
    </StyledDialogTitle>
  );
}
