// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"darkPrimaryPaper": `#292f32`,
	"darkPrimaryLight": `#464c51`,
	"darkPrimaryMain": `#2d3438`,
	"darkPrimaryDark": `#292f32`,
	"darkSecondary": `#bb86fc`,
	"darkTextPrimary": `rgba(255, 255, 255, 0.87)`,
	"darkTextSecondary": `rgba(255, 255, 255, 0.6)`,
	"darkTextTertiary": `rgba(255, 255, 255, 0.49)`,
	"darkTextDisabled": `rgba(255, 255, 255, 0.38)`,
	"primary100": `#fed1bf`,
	"primary300": `#df967a`,
	"primary500": `#a95939`,
	"primary700": `#752c0f`,
	"primary900": `#401301`,
	"primary1100": `#2b1301`,
	"secondary100": `#8fa8bb`,
	"secondary300": `#527590`,
	"secondary500": `#29506d`,
	"secondary700": `#0d2f4b`,
	"secondary900": `#021728`,
	"gray": `#4a5155`,
	"lightGray": `#6d7579`
};
export default ___CSS_LOADER_EXPORT___;
